import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { MainLayout} from "./screens/MainLayout";

const router = createBrowserRouter([
  {
    path: "/*",
    element: <MainLayout />,
  },
  {
    path: "/main-page",
    element: <MainLayout />,
  },
]);

export const App = () => {
  return <RouterProvider router={router} />;
};
