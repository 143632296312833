import React, { useState, useEffect } from "react";
import "./MainLayout.css";
import { MainPage } from "../MainPage/MainPage";
import { ProfilePage } from "../ProfilePage/ProfilePage";
import { FeedPage } from "../FeedPage/FeedPage";
import { EventPage } from "../EventPage/EventPage";

export const MainLayout = () => {
    const [activePage, setActivePage] = useState("main");
    const [selectedEventId, setSelectedEventId] = useState(null);
    const [showOverlay, setShowOverlay] = useState(false);

    const handleEventSelect = (eventId, fromPage) => {
        setSelectedEventId(eventId);
        setShowOverlay(true); // Show overlay
    };

    const handleNavigationClick = (page) => {
        setActivePage(page);
        setShowOverlay(false); // Automatically close EventPage overlay on navigation click
    };

    // Lock background scroll when overlay is active
    useEffect(() => {
        // Lock body scroll when the overlay is active
        document.body.style.overflow = showOverlay ? "hidden" : "auto";
    }, [showOverlay]);

    const renderPage = () => {
        switch (activePage) {
            case "main":
                return <MainPage onEventSelect={handleEventSelect} />;
            case "profile":
                return <ProfilePage />;
            case "feed":
                return <FeedPage onEventSelect={handleEventSelect} />;
            default:
                return <MainPage />;
        }
    };

    return (
        <div className="main-layout">
            {/* Render the active page content */}
            {renderPage()}

            {/* Conditionally render EventPage as a scrollable overlay */}
            {showOverlay && (
                <div className="overlay">
                    <div className="overlay-content">
                        <EventPage eventId={selectedEventId} onBackClick={() => setShowOverlay(false)} />
                    </div>
                </div>
            )}

            {/* Bottom Navigation */}
            <div className="bottom-navigation">
                <div className="nav-item" onClick={() => handleNavigationClick("feed")}>
                    <div className="nav-icon">📰</div>
                    <div className="nav-label">Feed</div>
                </div>
                <div className="nav-item" onClick={() => handleNavigationClick("main")}>
                    <div className="nav-icon">🎯</div>
                    <div className="nav-label">All Events</div>
                </div>
                <div className="nav-item">
                    <div className="nav-icon">🧾</div>
                    <div className="nav-label">My Slip</div>
                </div>
                <div className="nav-item" onClick={() => handleNavigationClick("profile")}>
                    <div className="nav-icon">👤</div>
                    <div className="nav-label">Profile</div>
                </div>
            </div>
        </div>
    );
};
